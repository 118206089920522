<template>
    <transition name="fade">
      <div v-if="isOpen" class="modal-overlay" @click.self="$emit('close')">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title">{{ title }}</h2>
            <button @click="$emit('close')" class="modal-close-btn_icon">✖</button>
          </div>
          
          
          <slot></slot>
          <!-- <div class="modal-footer">
            <button @click="$emit('close')" class="modal-close-btn">Close</button>
          </div> -->
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    props: {
      isOpen: Boolean,
      title: String
    }
  };
  </script>
  
  <style scoped>
  /* Overlay */
  .modal-overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Box */
  .modal-content {
    background: #fff;
    padding: 20px;
    width: 100%;
    max-width: 750px;
    max-height: 97vh; /* Limits modal height */
    overflow-y: auto; /* Enables vertical scrolling */
    border-radius: 12px;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.3);
    text-align: start; /* Ensures text aligns to start (left) */
    animation: slideIn 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Ensures content starts from the top */
    align-items: center; /* Centers content horizontally */
  }

  
  /* Title */
  .modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: center; /* Center title */
    position: relative; /* Needed for absolute positioning */
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

  
  /* Close Button */
  .modal-close-btn {
    background: #ff4d4d;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s;
  }
  .modal-close-btn_icon {
      position: absolute;
      background: none;
      border: none;
      /* font-size: 20px; */
      cursor: pointer;
      color: #333;
      right: -100px !important;
  }
  
  .modal-close-btn:hover {
    background: #cc0000;
  }
  
  /* Fade Animation */
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  
  /* Slide Animation */
  @keyframes slideIn {
    from {
      transform: translateY(-30px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  </style>
  