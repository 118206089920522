<template>
   <div class="col-lg-7 col-xl-7 col-12 ps-lg-3 pt-3 pt-lg-0">
      <div class="profileinformation">
         <ul class="nav nav-tabs border-0" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
               <button class="nav-link active" id="personel-tab" data-bs-toggle="tab"
                  data-bs-target="#personel" type="button" role="tab"
                  aria-controls="personel" aria-selected="true">Personal
                  Information
               </button>
            </li>
            <li class="nav-item" role="presentation">
               <button class="nav-link" id="preference-tab" data-bs-toggle="tab"
                  data-bs-target="#preference" type="button" role="tab"
                  aria-controls="preference" aria-selected="false">Partner Preference
               </button>
            </li>
         </ul>


            
         <div class="tab-content" id="myTabContent">
            
            <div class="tab-pane overflow-y-scroll fade show active" id="personel"
               role="tabpanel" aria-labelledby="personel-tab">

               <div class="info">
                  <Icon class="icon" icon="hugeicons:profile-02"/>
                  <div class="d-grid w-100 sta">
                     <h5>Basic Details</h5>
                     <hr>
                     <ul class="row list-unstyled pt-2">
                        <li class="col-12 col-sm-6" v-for="(item,index) in basicDetails" >
                           <LiRow :item="item" />
                        </li>
                     </ul>
                  </div>
               </div>
                                                
               <div  v-if="$store.getters.religion == 'Hindu'" class="info">
                  <Icon class="icon" icon="mdi:religion-judaic"/>
                  <div class="d-grid w-100">
                     <h5>Religious Information</h5>
                     <hr>
                     <ul class="row list-unstyled pt-2 sta">
                        <li class="col-12 col-sm-6 " v-for="(item) in religiousInformation" >
                           <LiRow :item="item" />
                        </li>
                     </ul>
                  </div>
               </div>                                 
                                                
               <div  v-else class="info">
                   <Icon class="icon" icon="mdi:religion-judaic"/>
                   <div class="d-grid w-100">
                       <h5>Religious Information</h5>
                       <hr>
                       <ul class="row list-unstyled pt-2 sta">
                           <li class="col-12 col-sm-6 " v-for="(item) in religiousInformation1" >
                              <LiRow :item="item" />
                           </li>
                       </ul>
                   </div>
               </div>

               <div class="info">
                   <Icon class="icon" icon="hugeicons:contact"/>
                   <div class="d-grid w-100">
                       <h5>Contact Details</h5>
                       <hr>
                       <ul class="row list-unstyled pt-2 sta">

                               <li class="col-12 col-sm-6 " v-for="(item) in contactDetails" >
                                   <LiRow :item="item" />
                               </li>
                            
                               <li v-if="!this.data?.user_data?.matched"class="col-2 col-sm-6 text-black" >
                                   <!-- <Icon icon="ic:baseline-lock" class="lock"/> -->
                               </li>
                       </ul>

                   </div>
               </div>

               <div class="info">
                   <Icon  class="icon" icon="solar:case-linear"/>
                   <div class="d-grid w-100">
                       <h5>Professional Information</h5>
                       <hr>
                       <ul class="row list-unstyled pt-2 sta">
                           <li class="col-12 col-sm-6 " v-for="(item) in professionalInformation" >
                              <LiRow :item="item" />
                           </li>
                       </ul>
                   </div>
               </div>
                                               
               <div class="info">
                   <Icon class="icon" icon="fluent:location-ripple-16-regular"/>
                   <div class="d-grid w-100 sta">
                       <h5>Family Details</h5>
                       <hr>
                       <ul class="row list-unstyled pt-2">

                           <li class="col-12 col-sm-6 " v-for="(item) in familyDetails" >
                                   <LiRow :item="item" />
                           </li>

                       </ul>
                       <h6>About Family</h6>
                       <p>{{ this.data?.user_data?.family?.about }}</p>
                   </div>
               </div>

               <div v-if="data?.user_data?.details?.religion === 'Hindu'" class="info">
                  <Icon class="icon" icon="mdi:zodiac-aries" />
                  <div class="d-grid w-100">
                     <h5>Horoscope Details</h5>
                     <hr>
                     <ul class="row list-unstyled pt-2 sta">
                           <li class="col-12 col-sm-6" v-for="(item, index) in horoscopeDeatils" :key="index">
                              <LiRow :item="item" />
                           </li>
                     </ul>
                     <!-- @click="downloadHoroscope(data?.user_data?.horoscope?.user_id)" -->
                     <!-- Download Button -->
                     <div class="d-flex justify-content-end mt-3">
                        <!-- <button
                           @click="downloadHoroscope(data?.user_data?.horoscope?.user_id)"
                           class="btn btn-primary btn-sm text-start"
                           style="background-color: blue !important; width: 160px"
                        >
                           <Icon class="me-2" icon="mdi:download" />
                           Download
                        </button> -->
                        <button
                           @click="downloadHoroscope(data?.user_data?.horoscope?.user_id)"
                           class="btn btn-sm custom-download-btn"
                        >
                           Download
                           <Icon class="ms-2 small-icon" icon="mdi:download" />
                        </button>



                        <Modal :isOpen="showModal" title="Horoscope" @close="showModal = false">
                           <div id="body_of_report">
                              <div class="container">

                                 <!-- Hidden Input Field -->
                                 <input type="hidden" id="taking_formail_sender" />

                                 <!-- Logo & Address -->
                                 <div class="row">
                                    <div class="col-4 text-left">
                                    <!-- <img src="@/assets/img/Frame 1.png" width="100%" class="logo" alt="Icon" /> -->
                                    <img src="@/assets/img/Frame 1.png" style="max-width: 100px; height: auto;" class="logo" alt="Icon" />
                                    </div>
                                    <div class="col-8 text-left">
                                    <b>Nandavanam Road, Palayam, Trivandrum - 695033</b><br />
                                    <b>Phone: 0471-2335143 / (M) 9495970147 / 9387116659</b><br />
                                    <b>Website: <a href="https://marryhelp.in/"> marryhelp.in</a></b><br />
                                    <b>Email: marryhelp.in@gmail.com</b>
                                    </div>
                                 </div>

                                 <hr />

                                 <!-- Profile Details -->
                                 <div class="details">
                                    <div class="row">
                                    <div class="col-4">Print Date & Time:</div>
                                    <div class="col-8">{{ currentDateTime }}</div> 
                                    </div>
                                    <div class="row">
                                    <div class="col-4">User Id:</div>
                                    <div class="col-8" id="receiver">{{ user?.data?.user_ids }}</div>
                                    </div>
                                    <!-- <div class="row">
                                    <div class="col-4">Office ID:</div>
                                    <div class="col-8" id="office_id_ax"></div>
                                    </div> -->
                                    <div class="row">
                                       <div class="col-4">Date of Birth:</div>
                                       <div class="col-8" id="dob">{{ formatDate(user?.data?.details?.dob) }}</div>
                                    </div>
                                    <div class="row">
                                       <div class="col-4">Religion:</div>
                                       <div class="col-8" id="religion">{{ user?.data?.details?.religion }}</div>
                                    </div>
                                    <div class="row">
                                       <div class="col-4">Caste:</div>
                                       <div class="col-8" id="cast">{{ user?.data?.details?.cast }}</div>
                                    </div>
                                 
                                    <div class="row">
                                       <div class="col-4">Height:</div>
                                       <div class="col-8" id="height">{{ user?.data?.details?.height }}</div>
                                    </div>
                                    <div class="row">
                                       <div class="col-4">Complexion:</div>
                                       <div class="col-8" id="complexion">{{ user?.data?.details?.complexion }}</div>
                                    </div>
                                    <div class="row">
                                       <div class="col-4">Qualification Details:</div>
                                       <div class="col-8" id="qualification">{{ user?.data?.education?.qualification_details }}</div>
                                    </div>
                                    <div class="row">
                                       <div class="col-4">Profession Details:</div>
                                       <div class="col-8" id="profession">{{ user?.data?.education?.profession_details }}</div>
                                    </div>
                                    <div class="row">
                                       <div class="col-4">Working Place:</div>
                                       <div class="col-8" id="workingplace">{{ user?.data?.education?.workplace }}</div>
                                    </div>
                                    <div class="row">
                                       <div class="col-4">Father's Occupation:</div>
                                       <div class="col-8" id="fatheroccu">{{ user?.data?.family?.father_occupation }}</div>
                                    </div>
                                    <div class="row">
                                       <div class="col-4">Mother's Occupation:</div>
                                       <div class="col-8" id="matheroccu">{{ user?.data?.family?.mother_occupation }}</div>
                                    </div>

                                    <!-- Sibling Details -->
                                    <div class="row">
                                    <div class="col-6">
                                       <div class="row">
                                          <div class="col-4">Brothers:</div>
                                          <div class="col-8" id="brothers">{{ user?.data?.family?.no_brother ?? 'nil' }}</div>
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="row">
                                          <div class="col-6 black">Brothers Married:</div>
                                          <div class="col-6" id="brothersmarried">{{ user?.data?.family?.married_no_brother  ?? 'nil' }}</div>
                                       </div>
                                    </div>
                                    </div>

                                    <div class="row">
                                    <div class="col-6">
                                       <div class="row">
                                          <div class="col-4">Sisters:</div>
                                          <div class="col-8" id="sisters">{{ user?.data?.family?.no_sisters ?? 'nil' }}</div>
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="row">
                                          <div class="col-6 black">Sisters Married:</div>
                                          <div class="col-6" id="sistersmarried">{{ user?.data?.family?.married_no_sisters ?? 'nil' }}</div>
                                       </div>
                                    </div>
                                    </div>

                                    <div class="row">
                                       <div class="col-4">Star & Time of Birth:</div>
                                          <div class="col-8" id="star_time">{{ formatStarTime(user?.data?.horoscope?.star, user?.data?.horoscope?.tob) }}</div>
                                       </div>
                                    </div>

                                 <br />
                                 <div class="col-12 d-flex flex-column align-items-center justify-content-center">
                                    <div class="row d-flex justify-content-center">
                                       <div id="jathakam" v-html="user?.data?.jk" class="text-center"></div>
                                    </div>
                                 </div>



                                 <!-- Additional Info -->
                                 <div class="additional-info">
                                    <b>
                                    Please visit our website
                                    <a href="https://marryhelp.in/"> marryhelp.in</a> and enter
                                    profile ID <span id="sender_span" style="color:red;">{{ user?.data?.user_ids }}</span> to view the photo.
                                    </b>
                                    <br />
                                    <b>
                                    If you are interested or not, inform the office at the earliest via
                                    mobile (<span id="mobile_for_religion" style="color:red;">{{ mobileForReligion }}</span>).
                                    </b>
                                    <br />
                                    <b>Friday Holiday | Sunday Working | Time: 9:30 AM - 5:00 PM</b>
                                 </div>

                                 <footer>
                                    <p style="text-align: right !important;">&copy; <span id="copyright-year"></span> marryhelp.in.</p>
                                 </footer>

                                 <div class="row no-print action-buttons">
                                    <div class="col-12 d-flex justify-content-center gap-2">
                                       <button  @click="printContent()" class="btn btn-default">
                                          <i class="fas fa-print"></i> Print
                                       </button>
                                       <!-- <button onclick="sendMail()" id="sendMailBtn" class="btn btn-default send-mail-btn">
                                          <i class="fas fa-paper-plane"></i> Send Mail
                                       </button> -->
                                       <button  @click="showModal = false" class="btn btn-danger modal-close-btn">
                                          ✖ Close
                                       </button>
                                    </div>
                                 </div><br><br>

                              </div>
                           </div>
                        </Modal>

                     </div>

                  </div>
               </div>   

               <div class="info">
                   <Icon class="icon" icon="material-symbols-light:menstrual-health-outline-rounded"/>
                   <div class="d-grid w-100">
                       <h5>Life Style</h5>
                       <hr>
                       <ul class="row list-unstyled pt-2 sta">
                           <li class="col-12 col-sm-6 " v-for="(item) in lifeStyle" >
                                   <LiRow :item="item" />
                           </li>
                       </ul>
                   </div>
               </div>

            </div>

            <div class="tab-pane overflow-y-scroll fade" id="preference" role="tabpanel"
                aria-labelledby="preference-tab">
                <div class="p-2 sta">
                    <h3>{{ $store.getters.user?.user_ids }} & {{this.data?.user_data?.user_ids}}</h3>
                    <div class="d-flex align-items-center">
                        <div class="flex-nowrap d-flex">
                            <img :src="$store.getters.base_url+$store.getters.user?.profile_pic" class="lazy" alt="">
                            <img :src=data?.base_url+data?.user_data?.profile_pic class="lazy" alt="">
                        </div>
                        <h5 class="ps-3">You match {{this.data?.user_data?.preferredMatches?.match}}/{{this.data?.user_data?.preferredMatches?.count_out_off}} of her preferences</h5>
                    </div>
                    <p>Your partner preferences match this member’s basic details and her partner preferences match your basic details</p>
                    <hr>
                    <div class="preference-text" v-for="(item) in preferences">
                        <Preference :item="item"/>
                    </div>
                </div>

                <br>
                <br>
            </div>

         </div>
      </div>
   </div>
</template>
<style scoped src="@/assets/css/application.css" ></style>
<script>
   import { Icon } from "@iconify/vue";
   import LiRow from "@/components/Authenticated/Show/LiRow.vue"
   import Preference from "./Preference.vue";
   import axios from 'axios';
   import jsPDF from "jspdf";
   import html2canvas from "html2canvas";
   import Modal from '@/components/horoscopeModel.vue';
   export default {
      props: {
         data: {
            required: true,
         },
      },
      components: {
        Icon,
        LiRow,
        Preference,
        Modal
      },
      data(){
        return{
               user: null,
               currentDateTime: this.getCurrentDateTime(),
               showModal: false,
               basicDetails:[
                  // {
                  //    head:'Profile Created By',
                  //    value:this.data?.user_data?.details?.created
                  // },
                  // {
                  //    head:'Name',
                  //    value:this.data?.user_data?.name
                  // },
                  //{
                  //   head:'Age',
                  //   value:this.data?.user_data?.age+' Yrs'
                  //},
                  {
                     head:'Date Of Birth',
                     ///value:this.data?.user_data?.details?.dob
                     value: this.formatDOB(this.data?.user_data?.details?.dob)
                  },
                  {
                     head:'Height',
                     value:this.data?.user_data?.details?.height+' cm'
                  },
                  {
                     head:'Gender',
                     value:this.data?.user_data?.details?.gender.charAt(0).toUpperCase()+ this.data?.user_data?.details?.gender.slice(1)
                  },
                  {
                     head:'Marital Status',
                     value:this.data?.user_data?.details?.marital_status
                  },
                  {
                     head:'Mother Tongue',
                     value:this.data?.user_data?.details?.mother_tongue
                  },
                  {
                     head:'Weight',
                     value:this.data?.user_data?.details?.weight? this.data?.user_data?.details?.weight+" kg":""
                  }
               ],
               religiousInformation:[
                  {
                     head:'Religion',
                     value:this.data?.user_data?.details?.religion
                  },
                  {
                     head:'Caste',
                     value:this.data?.user_data?.details?.cast
                  },
                  {
                     head:'Star',
                     value:this.data?.user_data?.horoscope?.star
                  },
                  {
                     head:'Dasa',
                     value:this.data?.user_data?.horoscope?.dasa
                  },
               ],
               religiousInformation1:[
                  {
                     head:'Religion',
                     value:this.data?.user_data?.details?.religion
                  },
                  {
                     head:'Caste',
                     value:this.data?.user_data?.details?.cast
                  },
                  
               ],
               contactDetails:[
                 
                  {
                     head:'Contact Admin',
                     value:'+91 94959 70147',
                     lock:!this.data?.user_data?.matched,
                  },
                  {
                     head:'Contact Admin',
                     value:'+91 94477 70147',
                     lock:!this.data?.user_data?.matched,
                  },
                  {
                     head:'Contact Admin',
                     value:'0471 2335143',
                     lock:!this.data?.user_data?.matched,
                  },
                  {
                     head:'Contact Admin',
                     value:'+91 9387116659',
                     lock:!this.data?.user_data?.matched,
                  },
               ],
               professionalInformation:[
                  // {
                  //    head:'Education',
                  //    value:this.data?.user_data?.education?.qualification ? this.data?.user_data?.education?.qualification:'Not Specified',
                  // },
                  {
                     head:'Education Detail',
                     value:this.data?.user_data?.education?.qualification_details ? this.data?.user_data?.education?.qualification_details:'Not Specified',
                  },
                  // {
                  //    head:'Occupation',
                  //    value:this.data?.user_data?.education?.profession ? this.data?.user_data?.education?.profession :'Not Specified',
                  // },
                  {
                     head:'Occupation Detail',
                     value:this.data?.user_data?.education?.profession_details ? this.data?.user_data?.education?.profession_details :'Not Specified',
                  },
                  {
                     head:'Workplace',
                     value:this.data?.user_data?.education?.workplace ? this.data?.user_data?.education?.workplace :'Not Specified',
                  },
                  {
                     head:'Annual Income',
                     value:this.data?.user_data?.education?.income ? this.data?.user_data?.education?.income :'Not Specified',
                  },
               ],
               location:[
                  {
                     head:'Country',
                     value:this.data?.user_data?.details?.country,
                  },
                  {
                     head:'State',
                     value:this.data?.user_data?.details?.state,
                  },
                  {
                     head:'City',
                     value:this.data?.user_data?.details?.district,
                  },
                  //  {
                  //     head:'Citizenship',
                  //     value:'India',
                  //  },
               ],
               familyDetails:[
                  // {
                  //    head:"Father's Name",
                  //    value:this.data?.user_data?.family?.father_name ? this.data?.user_data?.family?.father_name : '-',
                  // },
                  // {
                  //    head:"Mother's Name",
                  //    value:this.data?.user_data?.family?.mother_name ? this.data?.user_data?.family?.mother_name : '-',
                  // },
                  {
                     head:"Father's Occupation",
                     value:this.data?.user_data?.family?.father_occupation ? this.data?.user_data?.family?.father_occupation : '-',
                  },
                  {
                     head:"Mother's Occupation",
                     value:this.data?.user_data?.family?.mother_occupation ? this.data?.user_data?.family?.mother_occupation : '-',
                  },
                  {
                     head:'No. of Brothers',
                     value:this.data?.user_data?.family?.no_brother ? this.data?.user_data?.family?.no_brother : '-',
                  },
                  {
                     head:"No Brother's Married",
                     value:this.data?.user_data?.family?.married_no_brother ? this.data?.user_data?.family?.married_no_brother : '-',
                  },
                  {
                     head:'No. of Sisters',
                     value:this.data?.user_data?.family?.no_sisters ? this.data?.user_data?.family?.no_sisters : '-',
                  },
                  {
                     head:"No Sister's Married",
                     value:this.data?.user_data?.family?.married_no_sisters ? this.data?.user_data?.family?.married_no_sisters : '-',
                  },
               ],
               lifeStyle:[
                  {
                     head:'Eating Habits',
                     value:this.data?.user_data?.details?.diet_preference
                  },
                  //  {
                  //     head:'Drinking Habits',
                  //     value:'Non- Drinker',
                  //  },
                  //  {
                  //     head:'Smoking Habits',
                  //     value:'Non- Drinker',
                  //  },
               ],
               preferences:[
                  {
                     head:'Marital Status',
                     value:this.data?.user_data?.preferredMatches?.Marital_Status?.data,
                     strict:true,
                     preference:this.data?.user_data?.preferredMatches?.Marital_Status?.status
                  },
                  {
                     head:'Age',
                     value:this.data?.user_data?.preferredMatches?.age?.data,
                     strict:false,
                     preference:this.data?.user_data?.preferredMatches?.age?.status
                  },
                  {
                     head:'Height',
                     value:this.data?.user_data?.preferredMatches?.height?.data,
                     strict:false,
                     preference:this.data?.user_data?.preferredMatches?.height?.status
                  },
                  {
                     head:'Qualification',
                     value:this.data?.user_data?.preferredMatches?.qualification?.data,
                     strict:false,
                     preference:this.data?.user_data?.preferredMatches?.qualification?.status
                  },
                  {
                     head:'Profession',
                     value:this.data?.user_data?.preferredMatches?.profession?.data,
                     strict:false,
                     preference:this.data?.user_data?.preferredMatches?.profession?.status
                  },
                  {
                     head:'Religion',
                     value:this.data?.user_data?.preferredMatches?.religion?.data,
                     strict:false,
                     preference:this.data?.user_data?.preferredMatches?.religion?.status
                  },
                  {
                     head:'Caste',
                     value:this.data?.user_data?.preferredMatches?.cast?.data,
                     strict:false,
                     preference:this.data?.user_data?.preferredMatches?.cast?.status
                  },
                  {
                     head:'Country',
                     value:this.data?.user_data?.preferredMatches?.country?.data,
                     strict:false,
                     preference:this.data?.user_data?.preferredMatches?.country?.status,
                  },
                  {
                     head:'State',
                     value:this.data?.user_data?.preferredMatches?.state?.data,
                     strict:false,
                     preference:this.data?.user_data?.preferredMatches?.state?.status,
                  },
                  {
                     head:'District',
                     value:this.data?.user_data?.preferredMatches?.district?.data,
                     strict:false,
                     preference:this.data?.user_data?.preferredMatches?.district?.status,
                  },
                  

               ],
               horoscopeDeatils:[
                  {
                     head:'Star',
                     value:this.data?.user_data?.horoscope?.star
                  },
                  {
                     head:'Dob',
                     value:this.formatDate(this.data?.user_data?.horoscope?.dob)
                  },
                  {
                     head:'Tob',
                     value:this.NewformatStarTime(this.data?.user_data?.horoscope?.tob)
                  },
                  {
                     head:'Dasa',
                     value:this.data?.user_data?.horoscope?.dasa
                  },
                  {
                     head:'Janama Shatam Year',
                     value:this.data?.user_data?.horoscope?.janama_shatam_year
                  },
                  {
                     head:'Janama Shatam Month',
                     value:this.data?.user_data?.horoscope?.janama_shatam_month
                  },
                  {
                     head:'Janama Shatam Day',
                     value:this.data?.user_data?.horoscope?.janama_shatam_day
                  },
                  
               ]
         }

      },methods:{
         basicDetailsData(){
            if (this.data?.user_data?.preferredMatches?.HoroscopeMatch) {
               this.preferences.push({
                   head:'Horoscope Match',
                   value:this.data?.user_data?.preferredMatches?.HoroscopeMatch?.data,
                   strict:false,
                   preference:this.data?.user_data?.preferredMatches?.HoroscopeMatch?.status
                });
            }
         },
         formatDOB(dob) {
            if (!dob) return ''; 
            const date = new Date(dob);
            return date.toLocaleDateString('en-US', {
               year: 'numeric',
               month: 'long',
               day: 'numeric',
            });
         },
         downloadHoroscope(userId) {
            let url = process.env.VUE_APP__BASE_URL;
            if (!userId) {
            alert("User ID is missing!");
            return;
            }

            //console.log("Fetching horoscope data for user ID:", userId);

            axios
            .get(`${url}customer/get-user-horoscope-data`, {
               params: { userId: userId },
            })
            .then((response) => {
               ///console.log("API Response:", response.data); 
               this.user = response.data;
               this.showModal = true;
            })

            .catch((error) => {
               ///console.error("Error fetching horoscope:", error);
               alert("Failed to fetch horoscope data.");
            });
         },
         getCurrentDateTime() {
            const now = new Date();
            return now.toLocaleString(); 
         },
         formatDate(dateString) {
            if (!dateString) return "N/A"; 
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0"); 
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
         },
         formatStarTime(star, tob) {
            if (!star && !tob) return "N/A";
            if (!star) return this.formatTime(tob);
            if (!tob) return star;
            return `${star}, ${this.formatTime(tob)}`;
         },
         formatTime(time) {
            if (!time) return "";
            
            let [hours, minutes] = time.split(":").map(Number);
            
            let ampm = hours >= 12 ? "PM" : "AM";
            
            hours = hours % 12 || 12; 
            return `${hours}:${String(minutes).padStart(2, "0")} ${ampm}`;
         },
         NewformatStarTime(time) {
            if (!time) return "-";
            let [hours, minutes] = time.split(":").map(Number);
            let ampm = hours >= 12 ? "PM" : "AM";
            hours = hours % 12 || 12;
            return `${hours}:${String(minutes).padStart(2, "0")} ${ampm}`;
         },
         printContent() {
            const modalContent = document.getElementById("body_of_report");
            if (!modalContent) {
               //console.error("Modal content not found!");
               return;
            }

            const printArea = modalContent.cloneNode(true);

            const printWindow = window.open("", "_blank");

            const printStyles = `
               <style>
               body { font-family: Arial, sans-serif; margin: 0; padding: 20px; }
               .container { width: 100%; padding: 20px; }
               .details, .additional-info { margin-top: 20px; }
               .row { display: flex; justify-content: space-between; margin-bottom: 10px; }
               .col-4, .col-8, .col-6 { width: 50%; }
               .text-left { text-align: left; }
               footer { margin-top: 20px; text-align: right; }
               .no-print { display: none; }

               /* Image fixes for printing */
               img {
                  max-width: 150px !important; 
                  height: auto !important;
                  display: block;
                  margin: 0 auto;
               }
               </style>
            `;

            printWindow.document.open();
            printWindow.document.write(`
               <html>
               <head>
                     <title>marryhelp.in</title>
                     ${printStyles}
               </head>
               <body>
                     ${printArea.innerHTML}
               </body>
               </html>
            `);
            printWindow.document.close();

            printWindow.onload = () => {
               printWindow.focus();
               printWindow.print();
               printWindow.close();
            };
         },
        

         
      },
       mounted(){
        this.basicDetailsData();
        this.currentDateTime = this.getCurrentDateTime();
         setInterval(() => {
            this.currentDateTime = this.getCurrentDateTime();
         }, 1000);
      },
      computed: {
         mobileForReligion() {
            
            const HINDU_BOYS = "8078823147";
            const HINDU_GIRLS = "9446440147";
            const ELSE_NUMBER = "9495970147";

            const hinduBoysNumber = process.env.VUE_APP_HINDU_BOYS || HINDU_BOYS;
            const hinduGirlsNumber = process.env.VUE_APP_HINDU_GIRLS || HINDU_GIRLS;
            const elseNumber = process.env.VUE_APP_ELSE_NUMBER || ELSE_NUMBER;

            if (!this.user?.data?.details) return elseNumber;

            const religion = this.user.data.details.religion;
            const gender = this.user.data.details.gender;

            if (religion === "Hindu" && gender === "male") {
               return hinduBoysNumber;
            } else if (religion === "Hindu" && gender === "female") {
               return hinduGirlsNumber;
            } else {
               return elseNumber;
            }
         }
      }

    }
    
</script>


<style scoped>
   .icon {
         font-size: 3rem;
         background-color: red;
         padding: .5rem;
         border-radius: 2vh;
         color: white;
         height: fit-content;
         line-height: 0;
      }
   h5,h6 {
         color: black;
         text-align: start;
      }
   p, b {
         font-size: .85rem;
    
   }
   .tab-pane {
      background-color: #fff9f2;
      padding: .5rem;
      height: 100vh !important;
      scroll-behavior: smooth;
   }
   .col-4 {
      color:black;
   }
   .black {
      color: black;
   }
   
   .modal-close-btn {
      background: #ff4d4d;
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      transition: 0.3s;
   }
   .btn {
      padding: .2rem 2rem !important;
      display: block;
      background: linear-gradient(45deg, #2f3a46, #0056b3) !important;
      color: white !important;
      border: none !important;
      border-radius: 8px;
      padding: 8px 16px;
      width: 180px !important;
   }
   table, td, th {
      border: 2px solid black !important;
      border-collapse: collapse !important;
   }

   .jathakam {
      width: 100% !important;
      border: 2px solid black; /* Ensures outer border */
      border-collapse: collapse; /* Ensures no gaps between borders */
   }

   .jathakamtd {
      border: 2px solid black; /* Ensures inner cell borders */
      padding: 8px; /* Adds spacing inside the cells */
      text-align: center; /* Centers text */
      min-width: 50px; /* Ensures columns have a minimum width */
   }

   .jathakam td, .jathakam th {
      border: 2px solid black; /* Ensures consistent borders */
   }

   .jathakam p {
      margin: 0; /* Removes default paragraph margin */
   }
   @media print {
      .logo {
         max-width: 150px !important;
         height: auto !important;
         display: block; /* Ensures proper layout */
         margin: 0 auto; /* Centers the image */
      }
   }
   .custom-download-btn {
      background: linear-gradient(45deg, #2f3a46, #0056b3);
      color: white;
      font-weight: bold;
      border: none;
      border-radius: 8px;
      padding: 8px 16px;
      width: 180px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.3s ease;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
   }

   .small-icon {
      font-size: 18px; /* Adjust icon size */
   }

   .custom-download-btn:hover {
      background: linear-gradient(45deg, #0056b3, #b7c0cc);
      color:white;
      transform: scale(1.05);
   }

   .custom-download-btn:active {
      transform: scale(0.95);
   }

</style>